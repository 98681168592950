<div class="m-0 p-0 col-12">
  <nav class="navbar navbar-default bg-primary-translucid">
    <div class="container">
      <a class="navbar-brand col-md-6 col-sm-12 p-0 mt-2 mb-2 d-hide-sm" href="/principal">
        <img src="assets/img/Logo_titulo.svg" alt="logo.img" class="col-6 m-0 d-hide-sm" href="/principal ">
        <img src="assets/img/Logo_titulo.svg" alt="logo.img" class="col-8 m-0 d-show-sm" href="/principal ">
      </a>
      <!-- <a class="navbar-brand col-12 p-0 mt-2 mb-2 d-show-sm" href=" /principal">
        <div class="col-8">
          <img src="assets/img/Logo_titulo.svg" alt="logo.img" class="col-12 m-0 d-show-sm" href="/principal ">
        </div>
      </a> -->
      <!-- <div class="navbar-header navbar-toggler border-0 tex text-action-menu  d-show-menu-sm">
        <button type="button" class="btn" (click)="isCollapsed = !isCollapsed" aria-expanded="false">
          <i class="fas fa-bars bg-top-text"></i>
        </button>
      </div> -->
      
      <div class="container  border-white border-top">
        <div class="d-inline-block float-left" ngbDropdown>
          <button class="btn text text-white" id="dropdownForm1" ngbDropdownToggle>Nuestra Familia</button>
          <div ngbDropdownMenu aria-labelledby="dropdownForm1" class="p-0 m-0 bg-primary-translucid">
            <form class="px-3 py-3">
              <div class="form-group">
                <button type="button" class="btn btn-outline-cancel col" (click)="collapse.toggle()"
                  [attr.aria-expanded]="!isCollapsedNuestaFamilia" aria-controls="collapseExample">
                  Liderazgo
                </button>
                <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsedNuestaFamilia">
                  <div class="card">
                    <div class="card-body">
                      <button type="button" class="btn btn-sm btn-outline-primary col p-1 m-0"
                        (click)="goToNuestrosPastores.click()">
                        Nuestros pastores
                      </button>
                      <a href="principal/nuestrosPastores" class="d-none" #goToNuestrosPastores></a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <button type="button" class="btn btn-outline-cancel col" (click)="collapse2.toggle()"
                  [attr.aria-expanded]="!isCollapsedMinisterios" aria-controls="collapseExample">
                  Ministerios
                </button>
                <div #collapse2="ngbCollapse" [(ngbCollapse)]="isCollapsedMinisterios" class="">
                  <div class="card">
                    <div class="card-body">
                      <button type="button" class="btn btn-sm btn-outline-primary col p-1 m-0 mb-1"
                        (click)="goToRescateKids.click()">
                        Rescate Kids
                      </button>
                      <a href="principal/rescateKids" class="d-none" #goToRescateKids></a>
                      <button type="button" class="btn btn-sm btn-outline-primary col p-1 m-0 mb-1"
                        (click)="goToRescateconectado2.click()">
                        Conecta2
                      </button>
                      <a href="principal/conectado2" class="d-none" #goToRescateconectado2></a>
                      <button type="button" class="btn btn-sm btn-outline-primary col p-1 m-0 mb-1"
                        (click)="goTosomosWake.click()">
                        Nxt wake
                      </button>
                      <button type="button" class="btn btn-sm btn-outline-primary col p-1 m-0 mb-1"
                        (click)="goToNexo.click()">
                        Nexo
                      </button>
                      <a href="principal/nexo" class="d-none" #goToNexo></a>
                      <a href="principal/somosWake" class="d-none" #goTosomosWake></a>
                      <button type="button" class="btn btn-sm btn-outline-primary col p-1 m-0 mb-1"
                        (click)="goTostrong.click()">
                        Strong
                      </button>
                      <a href="principal/strong" class="d-none" #goTostrong></a>
                      <button type="button" class="btn btn-sm btn-outline-primary col p-1 m-0 mb-1"
                        (click)="goTosoloEllas.click()">
                        Solo ellas
                      </button>
                      <a href="principal/soloEllas" class="d-none" #goTosoloEllas></a>
                      <button type="button" class="btn btn-sm btn-outline-primary col p-1 m-0 mb-1"
                        (click)="goToNosLlegoLaTarde.click()">
                        Nos llegó la tarde
                      </button>
                      <a href="principal/nos-llego-la-tarde" class="d-none" #goToNosLlegoLaTarde></a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <button type="button" class="btn btn-outline-cancel col" (click)="goToFamiliasS.click()">
                  Familias
                </button>
                <a href="principal/familias" class="d-none" #goToFamiliasS></a>
              </div>
            </form>
            <div class="dropdown-divider"></div>
          </div>
        </div>
        <div class="d-inline-block float-left" ngbDropdown>
          <button class="btn text text-white" id="dropdownForm1" ngbDropdownToggle>Conócenos</button>
          <div ngbDropdownMenu aria-labelledby="dropdownForm1" class="p-0 m-0 bg-primary-translucid">
            <form class="px-3 py-3">
              <div class="form-group">
                <button type="button" class="btn btn-sm btn-outline-cancel col" (click)="goToSoyNuevo.click()">
                  Soy nuevo
                </button>
                <a href="principal/soynuevo" class="d-none" #goToSoyNuevo></a>
              </div>
              <div class="form-group">
                <button type="button" class="btn btn-sm btn-outline-cancel col" (click)="goToNuestrasReuniones.click()">
                  Nuestras reuniones
                </button>
                <a href="principal/NuestrasReuniones" class="d-none" #goToNuestrasReuniones></a>
              </div>
            </form>
            <div class="dropdown-divider"></div>
          </div>
        </div>
        <a href="principal/oracion"><span class=" p-1 text-top-text"> Oración</span></a>
        <a href="principal/dar"><span class="p-1 text-top-text"> Dar</span></a>
      </div>

    </div>



  </nav>

  <!-- <nav class="navbar navbar-default bg-primary-translucid">
    <hr>
    
  </nav> -->
</div>