<div class="modal-body">
  <h5 class="font-weight-bolder mb-3" align="center">¿Eres nuevo en la Familia de la fe?</h5>
  <div class="text-content-text">
    <span class="justify">
      ¡Bienvenido a casa! Nos encantaría conocer más de ti, y orar si tienes alguna necesidad de oración. ¡Gracias por
      tomar el tiempo de llenar estos datos!
    </span>
  </div>
  <div class="container-fluid p-0">
    <form [formGroup]="form">
      <div class="card  d-flex justify-content-between p-2 mt-1">
        <div class="small cont-weight-normal d-block">¿Decidiste hoy seguir a Jesús?</div>
        <div class="d-flex d-inline-block">
          <div class="p-3">
            <input type="form-check-input" checked="true" type="checkbox" /> SI
          </div>
          <div class="p-3">
            <input type="form-check-input" checked="false" type="checkbox" /> NO
          </div>
        </div>
      </div>
      <div class="card  d-flex justify-content-between p-2 mt-1">
        <div class="small cont-weight-normal d-block">Nombres</div>
        <input type="text" id="sendTime" formControlName="fecha" class="form-control" />
      </div>
      <div class="card  d-flex justify-content-between p-2 mt-1">
        <div class="small cont-weight-normal d-block">Fecha de nacimiento</div>
        <input type="text" id="sendTime" formControlName="fecha" class="form-control" />
      </div>
      <div class="card  d-flex justify-content-between p-2 mt-1">
        <div class="small cont-weight-normal d-block">Apellidos</div>
        <input type="text" id="sendTime" formControlName="fecha" class="form-control" />
      </div>
      <div class="card  d-flex justify-content-between p-2 mt-1">
        <div class="small cont-weight-normal d-block">Email</div>
        <input type="text" id="sendTime" formControlName="fecha" class="form-control" />
      </div>
      <div class="card  d-flex justify-content-between p-2 mt-1">
        <div class="small cont-weight-normal d-block">Telefono</div>
        <input type="text" id="sendTime" formControlName="fecha" class="form-control" />
      </div>
      <div class="card  d-flex justify-content-between p-2 mt-1">
        <div class="small cont-weight-normal d-block">Distrito donde vives</div>
        <input type="text" id="sendTime" formControlName="fecha" class="form-control" />
      </div>
      <div class="card  d-flex justify-content-between p-2 mt-1">
        <div class="small cont-weight-normal d-block">Petición de Oración</div>
        <input type="text" id="sendTime" formControlName="fecha" class="form-control" />
      </div>
      <div class="card  d-flex justify-content-between p-2 mt-1">
        <div class="small cont-weight-normal d-block">Doy consentimiento para que la familia de la fe almacene mis datos
          para ponerse en contacto conmigo o
          enviarme información:</div>
        <input type="checkbox" id="sendTime" formControlName="fecha" class="form-control form-check-input" />
      </div>
      <div align="center" class="mt-2">
        <button type="button" class="btn btn-secondary" (click)="dismiss()">Enviar</button>
      </div>
    </form>
  </div>
</div>