import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ScrollDirective } from '../../directive/scroll.directive';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit, AfterViewInit {

  numeroWhatsApp = '+51986393817';
  @ViewChild(ScrollDirective) scrollDirDirective: ScrollDirective;
  ocultarHeader = false;

  ngAfterViewInit() {
    this.scrollDirDirective.setScroll.subscribe((scrollPosition: number) => {
      this.ocultarHeader = scrollPosition >= 100;
    });
  }

  constructor() { }

  ngOnInit(): void {
  }

}
