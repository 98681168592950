import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MailingService } from 'src/app/service/mailing.service';

@Component({
  selector: 'app-soynuevo',
  templateUrl: './soynuevo.component.html',
  styleUrls: ['./soynuevo.component.scss']
})
export class SoynuevoComponent implements OnInit {

  form: FormGroup;
  @Input()
  code: string = null;
  @Input()
  absolute: string = null;
  estudiantes = [];
  date: Date;
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private http: HttpClient,

  ) {
    this.buildForm();
  }

  buildForm(): void {
    this.form = this.formBuilder.group({
      estudiante: [null, [Validators.required]],
      fecha: [null],
      comentarios: [null],
      estado: [null],
    });
  }

  ngOnInit(): void {
  }

  public dismiss(): void {
    this.activeModal.dismiss();
  }


}
