import { AfterViewInit, Directive, ElementRef, EventEmitter, Output } from '@angular/core';
import { fromEvent } from 'rxjs';

@Directive({
  selector: '[appScroll]'
})
export class ScrollDirective implements AfterViewInit {

  private scrollPosition: number;
  @Output() public setScroll = new EventEmitter();

  constructor(
    private el: ElementRef,
  ) { }

  ngAfterViewInit() {
    fromEvent(this.el.nativeElement, 'scroll').subscribe((e: any) => {
      this.scrollPosition = e.target['scrollTop'];
      this.setScroll.emit(this.scrollPosition);
    });
  }

}
