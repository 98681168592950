// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  HOST_API: 'http://127.0.0.1:8000/api/app/v1/web/',
  TOKEN: '?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImdhYnJpZWxzYWxjZWRvNjg3QGdtYWlsLmNvbSJ9.6XV_QDxKqgkGr6Y-6RH_ixtZTIC_mvLYITPhu3ruF9E',
  HOST_API_DNI: 'https://dniruc.apisperu.com/api/v1/dni/',
  //HOST_API: 'http://localhost:8080/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
