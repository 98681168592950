<!-- <div class="bgLogin">
  
</div> -->
<div class="col-12 bgLogin border" align="center">
  <div class="mx-auto">
    <div class="row no-gutters">
      <div class="col-sm-12 col-md-3 m-0 p-0 border-top-0 p-3">
        <div class="form-group">
          <img src="assets/img/8.png" alt="" class="col-12">
        </div>
      </div>
      <div class="col-sm-12 col-md-3 m-0 p-0">
        <div class="col-12 mt-2">
          <div class="form-group">
            <div class="d-flex flex-column">
              <label for="description" class="font-weight-light text-argument text-white">
                <strong>Conócenos</strong>
              </label>
              <div class="col-9">
                <div class="d-inline-block float-right d-flex flex-column text-align-end" align="justify">
                  <a href="principal/nuestrosPastores">
                    <label for="description" class="font-weight-light text-argument mt-1 text-white">
                      <i class="fas fa-stream"></i>
                      Nuestros pastores
                    </label>
                  </a>
                  <a href="principal/NuestrasReuniones">
                    <label for="description" class="font-weight-light text-argument mt-1 text-white">
                      <i class="fas fa-stream"></i>
                      Nuestras reuniones
                    </label>
                  </a>
                  <a href="principal/soynuevo">
                    <label for="description" class="font-weight-light text-argument mt-1 text-white">
                      <i class="fas fa-stream"></i>
                      Soy nuevo
                    </label>
                  </a>
                  <a href="principal">
                    <label for="description" class="font-weight-light text-argument mt-1 text-white">
                      <i class="fas fa-stream"></i>
                      Nuestro ADN
                    </label>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-3 m-0 p-0">
        <div class="col-12 mt-4 form-group ">
          <div class="d-flex flex-column">
            <label for="description" class="font-weight-light text-argument text-white">
              <strong>Ministerios</strong>
            </label>
            <div class="col-8">
              <div class="d-inline-block float-right d-flex flex-column text-align-end" align="justify">
                <a href="principal/rescateKids">
                  <label for="description" class="font-weight-light text-argument mt-1 text-white">
                    <i class="fas fa-stream"></i>
                    Rescate Kids
                  </label>
                </a>
                <a href="principal/conectado2">
                  <label for="description" class="font-weight-light text-argument mt-1 text-white">
                    <i class="fas fa-stream"></i>
                    Conecta2
                  </label>
                </a>
                <a href="principal/nexo">
                  <label for="description" class="font-weight-light text-argument mt-1 text-white">
                    <i class="fas fa-stream"></i>
                    Nexo
                  </label>
                </a>
                <a href="principal/somosWake">
                  <label for="description" class="font-weight-light text-argument mt-1 text-white">
                    <i class="fas fa-stream"></i>
                    Nxt Wake
                  </label>
                </a>
                <a href="principal/soloEllas">
                  <label for="description" class="font-weight-light text-argument mt-1 text-white">
                    <i class="fas fa-stream"></i>
                    Solo Ellas
                  </label>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-3 m-0 p-0 border-top-0  p-3">
        <div class="col-12 mt-2">
          <div class="form-group mb-4">
            <label for="description" class="font-weight-light text-argument text-white">
              <strong>Siguenos en </strong>
            </label>
            <div class="col-8 mt-1 mx-auto">
              <div class="" align="center">
                <a href="https://www.facebook.com/delafefamilia?mibextid=LQQJ4d"><i
                    class="fab fa-facebook-square fa-lg p-3" style="color: #ffffff;"></i></a>
                <a href="https://instagram.com/delafefamilia?igshid=MzRlODBiNWFlZA=="><i
                    class="fab fa-instagram-square fa-lg p-3" style="color: #ffffff;"></i></a>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mx-auto col-12 mt-5">
      <h5 class="text-white">
        © 2023, Familia de la Fe. Todos los derechos reservados.
      </h5>
    </div>
    <hr>
  </div>
</div>