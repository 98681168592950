<div class="modal-body">
  <h5 class="font-weight-bolder mb-3" align="center">Familia de la FE</h5>
  <div class="container-fluid p-0">
    <form [formGroup]="form">
      <div class="card  d-flex justify-content-between p-2 mt-1">
        <div class="small cont-weight-normal d-block">Número de tarjeta:</div>
        <input type="number" formControlName="numberCard" class="form-control"
          placeholder="Ingrese el número de tarjeta" />
      </div>
      <div class="card  d-flex justify-content-between p-2 mt-1">
        <div class="small cont-weight-normal d-block">Fecha de vencimiento:</div>
        <input type="text" id="expiryDate" formControlName="fechaVencimiento" class="form-control" />
      </div>
      <div class="card  d-flex justify-content-between p-2 mt-1">
        <div class="small cont-weight-normal d-block">CVV:</div>
        <input type="number" formControlName="cvv" class="form-control" placeholder="Ingrese el CVV" />
      </div>
      <div class="card  d-flex justify-content-between p-2 mt-1">
        <div class="small cont-weight-normal d-block">Nombres y apellidos:</div>
        <input type="text" formControlName="fullname" class="form-control"
          placeholder="Ingrese sus nombres y apellidos" />
      </div>
      <div class="card  d-flex justify-content-between p-2 mt-1">
        <div class="small cont-weight-normal d-block">Email:</div>
        <input type="email" formControlName="correo" class="form-control" placeholder="Ingrese su correo electrónico" />
      </div>
      <div class="card  d-flex justify-content-between p-2 mt-1">
        <div class="small cont-weight-normal d-block">Monto:</div>
        <input type="number" formControlName="monto" class="form-control" placeholder="Ingrese el monto a donar" />
      </div>
      <div align="center" class="mt-2">
        <button type="button" class="btn btn-primary" (click)="dismiss()">Donar</button>
        <button type="button" class="btn btn-secondary">Cancelar</button>
      </div>
    </form>
  </div>
</div>