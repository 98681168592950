import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopComponent } from './components/top/top.component';
import { FooterComponent } from './components/footer/footer.component';
import { ContentComponent } from './components/content/content.component';
import { AppRoutingModule } from '../app-routing.module';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ScrollDirective } from './directive/scroll.directive';



@NgModule({
  declarations: [
    ContentComponent,
    TopComponent,
    FooterComponent,
    ScrollDirective
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    NgbModule
  ],
  exports: [
    ContentComponent,
    TopComponent,
    FooterComponent
  ]
})
export class SharedModule { }
