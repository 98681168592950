import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-pagos',
  templateUrl: './pagos.component.html',
  styleUrls: ['./pagos.component.scss']
})
export class PagosComponent implements OnInit {

  form: FormGroup;
  @Input()
  code: string = null;
  @Input()
  absolute: string = null;
  estudiantes = [];
  date: Date;
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private http: HttpClient,

  ) {
    this.buildForm();
  }

  buildForm(): void {
    this.form = this.formBuilder.group({
      numberCard: [null, [Validators.required]],
      fechaVencimiento: [null, [Validators.required]],
      cvv: [null, [Validators.required]],
      fullname: [null, [Validators.required]],
      correo: [null, [Validators.required]],
      monto: [null, [Validators.required]]
    });
  }

  ngOnInit(): void {
  }

  public dismiss(): void {
    this.activeModal.dismiss();
  }
}
